export const scrollToElement = (id: string, animated?: boolean): void => {
  const element = document.getElementById(id);
  const scrollBehavior = animated ? 'smooth' : 'auto';
  element && element.scrollIntoView({ behavior: scrollBehavior });
};

export const checkIsBrowser = (): boolean => !(
  window.navigator.userAgent.includes('Node.js') || window.navigator.userAgent.includes('jsdom')
);

export const checkIsMilanHost = (): boolean => {
  const hostname = document.location.hostname ? document.location.hostname : window.location.hostname;
  return ['www.acmilan.com', 'acmilan.com'].includes(hostname);
};
