import sendRequest from './request';

export interface GetFeedDataInterface {
  path?: string;
  feedTime?: number; // prevent Live data server cashing
  feedType: string;
  seasonId: string;
  competition: string;
  gameId?: string;
  language?: string;
}

/* eslint-disable @typescript-eslint/camelcase */
export function getFeedData(requestParams: GetFeedDataInterface): Promise<{[propName: string]: unknown}> {
  const feedTime = requestParams.feedTime ? { feedTime: requestParams.feedTime } : {};
  const gameId = requestParams.gameId ? { game_id: requestParams.gameId.replace('g', '') } : {};
  const language = requestParams.language ? { language: requestParams.language } : {};
  const params = {
    ...feedTime,
    ...gameId,
    ...language,
    feed_type: requestParams.feedType,
    json: true,
    season_id: requestParams.seasonId,
    competition: requestParams.competition,
  };

  return sendRequest({
    path: requestParams?.path ?? '',
    params,
  });
}
