import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styled from 'styled-components';

import { checkIsBrowser } from '@app/helpers/documentHelpers';
import { CloseButton } from '@app/components';

interface ModalProps {
  children?: JSX.Element[] | JSX.Element | null | undefined;
  closeLabelId?: string;
  onClose: () => void;
  background?: string;
  controls?: boolean;
  preview?: boolean;
  open: boolean;
}

const Modal = ({
  children, background, open, onClose, controls = true, closeLabelId, preview = false,
}: ModalProps): React.ReactElement | null => {
  const isBrowser = checkIsBrowser();

  const onKeyDown = ({ keyCode }): void => { if (keyCode === 27) onClose(); };
  const toggleModalWindowMode = (): void => {
    if (open) {
      disableBodyScroll();
      document.addEventListener('keydown', onKeyDown, false);
    } else {
      enableBodyScroll();
      document.removeEventListener('keydown', onKeyDown, false);
    }
  };

  useEffect(() => { toggleModalWindowMode(); }, [open]);

  return (
    isBrowser && open
      ? ReactDOM.createPortal(
        <ModalGlobalWrapper preview={preview}>
          <CloseButtonWrapper>
            {controls && <CloseButton onClick={onClose} labelId={closeLabelId} />}
          </CloseButtonWrapper>
          <ModalContainer>
            {children}
          </ModalContainer>
          <Background color={background} />
        </ModalGlobalWrapper>,
        document.body,
      )
      : null
  );
};

export default Modal;

const ModalGlobalWrapper = styled.section<{ preview: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  -webkit-animation: ${({ preview }): string => (preview ? 'unset' : 'fade-in .21s cubic-bezier(0.390, 0.575, 0.565, 1.000) both')};
  animation: ${({ preview }): string => (preview ? 'unset' : 'fade-in .21s cubic-bezier(0.390, 0.575, 0.565, 1.000) both')};
  z-index: ${({ preview }): number => (preview ? -9 : 999999999)};
`;

const ModalContainer = styled.div`
  position: relative;
  overflow: auto;
  flex: 0 0 100%;
  z-index: 1000;
`;

const Background = styled.section<{ color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 998;
  background-color: ${({ color }): string => (color ?? 'black')};
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999999;
`;
