import React from 'react';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import AppRoutes from '@app/constants/routes';
import { GOOGLE_VERIFICATION_CODE } from '@app/constants/googleConstants';
import { getConfiguration } from '@app/store/actions/configurationActions';
import { getWebSiteSchema } from '@app/helpers/structuredMarkupHelpers';
import { checkIsMilanHost } from '@app/helpers/documentHelpers';

import { MetaJsonLD, Partners, Loader } from '@app/components';
import { LocalizedSwitch } from '@app/components/Localization';
import { useIsConfigLoaded, useLanguage } from '@app/components/Hooks';
import { NewsPremiumModal } from '@app/components/News';
import Header from '@app/components/Header/Header';
import Footer from '@app/components/Footer/Footer';

const ViewPort = (): React.ReactElement | null => {
  const language = useLanguage();

  const webSiteJsonLD = getWebSiteSchema();
  const isConfigLoaded = useIsConfigLoaded();
  const isMilanHost = checkIsMilanHost();

  return isConfigLoaded ? (
    <Container>
      <NewsPremiumModal />
      <Helmet htmlAttributes={{ lang: language }}>
        {!isMilanHost && (<meta name="robots" content="noindex, nofollow" />)}
        <meta name="google-site-verification" content={GOOGLE_VERIFICATION_CODE} />
      </Helmet>
      <MetaJsonLD json={webSiteJsonLD} />
      <Header />
      <Main>
        <LocalizedSwitch>
          <Route path={AppRoutes.ArticleLanding.path} component={AppRoutes.ArticleLanding.component} />
          <Route path={AppRoutes.VideoLanding.path} component={AppRoutes.VideoLanding.component} />
          <Route path={AppRoutes.GalleryLanding.path} component={AppRoutes.GalleryLanding.component} />
          <Route path={AppRoutes.Articles.path} component={AppRoutes.Articles.component} />
          <Route path={AppRoutes.Videos.path} component={AppRoutes.Videos.component} />
          <Route path={AppRoutes.Gallery.path} component={AppRoutes.Gallery.component} />
          <Route path={AppRoutes.Search.path} component={AppRoutes.Search.component} />
          <Route path={AppRoutes.PlayerProfile.path} component={AppRoutes.PlayerProfile.component} exact />
          <Route path={AppRoutes.Teams.path} component={AppRoutes.Teams.component} exact />
          <Route path={AppRoutes.Ticketing.path} component={AppRoutes.Ticketing.component} />
          <Route path={AppRoutes.MatchCenter.path} component={AppRoutes.MatchCenter.component} />
          <Route path={AppRoutes.Season.path} component={AppRoutes.Season.component} />
          <Route path={AppRoutes.WebTemplate.path} component={AppRoutes.WebTemplate.component} exact />
          <Route path={AppRoutes.WebTemplateTab.path} component={AppRoutes.WebTemplateTab.component} exact />
          <Route path={AppRoutes.Home.path} component={AppRoutes.Home.component} exact />
          <Route path="*" component={AppRoutes.NotFound.component} exact />
        </LocalizedSwitch>
      </Main>
      <Partners />
      <Footer />
    </Container>
  ) : <Loader />;
};

ViewPort.serverFetch = getConfiguration;

export default ViewPort;

const Container = styled.div`
  --nav-header-left-padding: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Main = styled.div`
  flex-grow: 1;
  padding-top: calc(var(--navigation-header-height) + var(--top-header-height));

  & > div {
    padding-bottom: 80px;

    @media ${Devices.tablet} {
      padding-bottom: 120px;
    }
  }
`;
