import { OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';

export const MilanTeamId = '120';
export const MilanWomenTeamId = '14734';

/* eslint-disable @typescript-eslint/camelcase */
export const OptaSDMilanTeamIdMap: {
  [value in OptaSDCompetitionsMap]: string
} = {
  [OptaSDCompetitionsMap.Primavera]: '4zporehl5b4fof2wydmvegf86',
  [OptaSDCompetitionsMap.Primavera2]: '4zporehl5b4fof2wydmvegf86',
  [OptaSDCompetitionsMap.UefaYouthLeague]: '4zporehl5b4fof2wydmvegf86',
  [OptaSDCompetitionsMap.FuturoSeriaC]: 'ek715qtl6pgjmf5pa7g9jbo5w',
  [OptaSDCompetitionsMap.FuturoCoppaItalia]: 'ek715qtl6pgjmf5pa7g9jbo5w',
};
